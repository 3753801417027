#home {
  // disable the default padding styles
  .app__wrapper {
    padding: 0;
  }
}

.app__home {
  width: 100%;
  height: 100%;
  padding: 8rem 2rem 0rem;
}

.app__home-info {
  flex: 0.8;
  display: flex;
  flex-direction: row;
  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 0;
  }
}

.app__home-badge {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }
  .badge-cmp {
    margin-top: 1rem;
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }
}

.app__home-img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  img {
    width: 100%;
    object-fit: contain;
  }
}
.p-text i {
  color: var(--secondary-color);
}
