.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 90px;
    height: 40px;

    @media screen and (min-width: 2000px) {
      width: 190px;
      height: 70px;
    }
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;

    width: 80%;
    max-width: 240px;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    .hix {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      
      li {
        margin: 1rem;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    
  }
}

@media screen and (min-width: 501px) {
  .sidebar__social {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .sidebar__social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-self: center;    
    padding: 1rem;

    a {
      outline: none;
    }

    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s ease-in-out;

      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }

      &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        cursor: pointer;
        svg {
          color: var(--white-color);
          cursor: pointer;
        }
      }

      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;

        margin: 0.5rem 0;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

