.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__about-info {
  flex: 0.8 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 2rem;

  .p-text-about{
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: var(--gray-color);
    margin: 0;
    padding: 0 0 0 2rem;
  }
}

.aboutPage {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .aboutMeSection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem;
  }
}


.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  padding: 2rem;
}

.head-text {
  text-align: left !important;
}

